import { query } from '@solidjs/router';
import TTLCache from '@isaacs/ttlcache';
import type { contentful, ContentfulModel } from '@troon/contentful';
import type { CachedFunction } from '@solidjs/router';

const defaultTTL = 300_000;
const ttlCache = new TTLCache({ ttl: defaultTTL, checkAgeOnGet: true, max: 500 });

export function createContentfulRequest<
	M extends ContentfulModel,
	R extends ReturnType<(typeof contentful)[M]['get']> = ReturnType<(typeof contentful)[M]['get']>,
	O extends Parameters<(typeof contentful)[M]['get']>[1] = Parameters<(typeof contentful)[M]['get']>[1],
>(model: M): CachedFunction<(id: string, opts?: O) => Promise<R>> {
	return query(async (id: string, opts?: O): Promise<R> => {
		const params = new URLSearchParams();
		for (const [key, val] of Object.entries(opts ?? {})) {
			params.set(key, `${val}`);
		}

		const key = `${model}/${id}?${params.toString()}`;
		const cached = ttlCache.get(key);
		if (cached) {
			return Promise.resolve(cached as R);
		}

		const res = await fetch(`https://troon.com/content/${model}/${id}?${params.toString()}`, { mode: 'cors' });
		const data = (await res.json()) as Awaited<R>;

		ttlCache.set(key, data);
		return data;
	}, `contentful-${model}`);
}

export function createContentfulListRequest<
	M extends ContentfulModel,
	R extends ReturnType<(typeof contentful)[M]['list']> = ReturnType<(typeof contentful)[M]['list']>,
	O extends Parameters<(typeof contentful)[M]['list']>[0] = Parameters<(typeof contentful)[M]['list']>[0],
>(model: M, opts?: O): CachedFunction<(pagination?: O) => Promise<R>> {
	return query(async (inlineOpts?: O) => {
		const params = new URLSearchParams();
		for (const [key, val] of Object.entries({ ...opts, ...inlineOpts })) {
			params.set(key, `${val}`);
		}

		const key = `${model}?${params.toString()}`;
		const cached = ttlCache.get(key);
		if (cached) {
			return Promise.resolve(cached as R);
		}

		const res = await fetch(`https://troon.com/content/${model}?${params.toString()}`, { mode: 'cors' });
		const data = (await res.json()) as Awaited<R>;

		ttlCache.set(key, data);
		return data;
	}, `contentful-list-${model}`);
}
