import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { For, Show } from 'solid-js';
import { HorizontalRule, Picture, Section, TextLink, Heading as UIHeading } from '@troon/ui';
import { Dynamic } from 'solid-js/web';
import type { Block, Document as RichTextDocument, Inline, Text as TextNode } from '@contentful/rich-text-types';
import type { Component } from 'solid-js';

export function RichText(props: { document: RichTextDocument }) {
	return (
		<Section>
			<For each={props.document.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</Section>
	);
}

function Paragraph(props: Block) {
	return (
		<Show
			// Filter out empty paragraphs
			when={
				Array.isArray(props.content) &&
				!(props.content.length === 1 && props.content[0]?.nodeType === 'text' && props.content[0]?.value?.trim() === '')
			}
		>
			<p>
				<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
			</p>
		</Show>
	);
}
function Heading(props: Block) {
	return (
		<UIHeading as={nodeTypeToHeadingLevel[props.nodeType as keyof typeof nodeTypeToHeadingLevel]}>
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</UIHeading>
	);
}
const nodeTypeToHeadingLevel = {
	'heading-1': 'h1',
	'heading-2': 'h2',
	'heading-3': 'h3',
	'heading-4': 'h4',
	'heading-5': 'h5',
	'heading-6': 'h6',
} as const;

function Text(props: TextNode) {
	return (
		<Show
			when={props.marks[0]}
			fallback={
				<For each={props.value.split('\n')}>
					{(item, i) => (
						<>
							{item}
							<Show when={i() < props.value.split('\n').length - 1}>
								<br />
							</Show>
						</>
					)}
				</For>
			}
		>
			{(mark) => <Dynamic component={mark().type === 'bold' ? 'b' : 'span'}>{props.value}</Dynamic>}
		</Show>
	);
}

function Link(props: Inline) {
	return (
		<TextLink href={props.data.uri}>
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</TextLink>
	);
}

function UnorderedList(props: Block) {
	return (
		<ul class="flex list-disc flex-col gap-2 ps-6">
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</ul>
	);
}
function OrderedList(props: Block) {
	return (
		<ol class="flex list-decimal flex-col gap-2 ps-6">
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</ol>
	);
}
function ListItem(props: Block) {
	return (
		<li>
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</li>
	);
}

function Asset(props: Block) {
	return (
		<Picture
			src={props.data.target.fields.file.url}
			width={800}
			height={Math.round(
				800 / (props.data.target.fields.file.details.image.width / props.data.target.fields.file.details.image.height),
			)}
			sizes="(min-width: 1440px) 50vw, 95vw"
			crop="center"
			mode="contain"
			alt={props.data.target.fields.description ?? props.data.target.fields.title}
			class="w-full rounded"
		/>
	);
}

function Table(props: Block) {
	return (
		<table>
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</table>
	);
}

function Tr(props: Block) {
	return (
		<tr>
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</tr>
	);
}

function Th(props: Block) {
	return (
		<th class="border border-white bg-brand-700 px-4 py-2 text-start text-white">
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</th>
	);
}

function Td(props: Block) {
	return (
		<td class="border border-neutral px-4 py-2">
			<For each={props.content}>{(item) => <Dynamic component={components[item.nodeType]} {...item} />}</For>
		</td>
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: Record<BLOCKS | INLINES | 'text', Component<any>> = {
	[BLOCKS.DOCUMENT]: () => null,
	[BLOCKS.PARAGRAPH]: Paragraph,
	[BLOCKS.HEADING_1]: Heading,
	[BLOCKS.HEADING_2]: Heading,
	[BLOCKS.HEADING_3]: Heading,
	[BLOCKS.HEADING_4]: Heading,
	[BLOCKS.HEADING_5]: Heading,
	[BLOCKS.HEADING_6]: Heading,
	[BLOCKS.UL_LIST]: UnorderedList,
	[BLOCKS.OL_LIST]: OrderedList,
	[BLOCKS.LIST_ITEM]: ListItem,
	[BLOCKS.HR]: HorizontalRule,
	[BLOCKS.QUOTE]: Paragraph,
	[BLOCKS.EMBEDDED_ASSET]: Asset,
	[BLOCKS.EMBEDDED_ENTRY]: () => null,
	[BLOCKS.EMBEDDED_RESOURCE]: () => null,
	[BLOCKS.TABLE]: Table,
	[BLOCKS.TABLE_ROW]: Tr,
	[BLOCKS.TABLE_HEADER_CELL]: Th,
	[BLOCKS.TABLE_CELL]: Td,
	text: Text,
	[INLINES.HYPERLINK]: Link,
	[INLINES.ASSET_HYPERLINK]: Link,
	[INLINES.EMBEDDED_RESOURCE]: () => null,
	[INLINES.EMBEDDED_ENTRY]: () => null,
	[INLINES.ENTRY_HYPERLINK]: () => null,
	[INLINES.RESOURCE_HYPERLINK]: () => null,
};
