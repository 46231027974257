import { BreadcrumbItem, Breadcrumbs, Details, Heading, Summary } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { For, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { createContentfulRequest } from '../../../../../../modules/contentful';
import { Content } from '../../../../../../components/main-content';
import { RichText } from '../../../../../../components/rich-text';
import type { RouteSectionProps } from '@solidjs/router';

export default function Faq(props: RouteSectionProps) {
	const faq = createAsync(async () => getFaq(props.params.id!));

	return (
		<Content>
			<Show when={faq()?.fields}>
				<Breadcrumbs omitHome>
					<BreadcrumbItem href={`/facility/${props.params.facility}/resources`}>Resources</BreadcrumbItem>
					<BreadcrumbItem href={`/facility/${props.params.facility}/resources/faqs/${faq()?.fields.id}`}>
						{faq()?.fields.title}
					</BreadcrumbItem>
				</Breadcrumbs>

				<Heading as="h1">{faq()?.fields.title}</Heading>

				<div class="flex flex-col gap-8 rounded border border-neutral bg-white p-4 xl:p-8">
					<ul class="divide-y divide-neutral-500">
						<For each={faq()?.fields.faq}>
							{(faq) => (
								<li>
									<Details>
										<Summary class={twJoin('group-open:mb-0', 'py-4 group-open:pb-2')}>{faq?.fields.question}</Summary>
										<div class="pb-4">
											<RichText document={faq!.fields.answer} />
										</div>
									</Details>
								</li>
							)}
						</For>
					</ul>
				</div>
			</Show>
		</Content>
	);
}

const getFaq = createContentfulRequest('faqs');
